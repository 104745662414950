// export const API_BASE_URL = 'https://api.askaii.co.uk'
export const API_BASE_URL = 'https://uat-api.askaii.co.uk'
export const API_DASHBOARD_DOMAIN = `https://dashboard.askaii.co.uk`
export const API_WEB_DOMAIN = `https://askaii.co.uk/`

export const AUTH_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InBhcGFqYW5pMTIzNEBnbWFpbC5jb20iLCJuYmYiOjE3MzI1MjY0NjksImV4cCI6MTczMjUzMDA2OSwiaWF0IjoxNzMyNTI2NDY5fQ.aapZxuNOmdVQwmGN1sWJWJ64V4cSeIZGpDfwOZOohG8'

// export const API_DASHBOARD_DOMAIN = `http://localhost:3001`
// dashboarlink "http://dashboard.askaii.co.uk/"
