import React, { createContext, useState, useContext, useEffect } from 'react'
import { API_BASE_URL, AUTH_TOKEN } from '../../Config/Config'

const BookingContext = createContext()

export function useBooking() {
  return useContext(BookingContext)
}

export function BookingProvider({ children }) {
  const [bookingData, setBookingData] = useState(() => ({
    quoteID: '',
    quoteCode: '',
    airportName: '',
    airportId: null,
    airportCode: '',
    parkingStart: '',
    parkingEnd: '',
    formattedParkingStart: '',
    formattedParkingEnd: '',
    discountCode: '',
    totalDays: 0,
    totalDaysCost: 0,
    totalCostAll: 0,
    outBoundTerminalName: '',
    outBoundTerminalId: '',
    outBoundTerminalCost: 0,
    inBoundTerminalName: '',
    inBoundTerminalId: '',
    inBoundTerminalCost: 0,
    carCleanName: 'None',
    carCleanId: null,
    carCleanCost: 0,
    electricChargeName: 'None',
    electricChargeId: null,
    electricChargeCost: 0,
    userIsRegister: false,
    promotions: null,
    userTitle: '',
    userFirstName: '',
    userSurName: '',
    email: '',
    userCompanyName: '',
    userMobileNo: '',
    userSource: '',
    otherUserSource: '',
    carRegistrationNo: '',
    carMakeName: '',
    carMakeId: '',
    carOtherMakeName: '',
    carOtherMakeId: '',
    carModelName: '',
    carModelId: '',
    carOtherModelName: '',
    carOtherModelId: '',
    carColourName: '',
    carColourId: '',
    carOtherColourName: '',
    carOtherColourId: '',
    flyingTo: '',
    outBoundFlightNo: '',
    inBoundFlightNo: '',
    flyingFrom: '',
    isAgreeOnTerms: '',
    paymentMethod: 'card',
    postCode: '',
    address: '',
    town: '',
    county: '',
    country: 'United Kingdom',
    paymentState: '',
    customerId: '',
    toggleDetails: false,
  }))

  const [cardData, setCardData] = useState(() => ({
    creditCard: {
      name: '',
      cardNumber: '',
      expirationYear: '',
      expirationMonth: '',
      cvc: '',
    },
  }))

  const formatDisplayDate = (isoDate) => {
    const date = new Date(isoDate)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    return date.toLocaleString('en-US', options).replace(' at ', ' ')
  }

  const fetchDataAndValidate = async (quoteCode, navigate) => {
    if (!quoteCode) {
      navigate('/')
      return
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/web/GetQuoteByCode/${quoteCode}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: AUTH_TOKEN,
          },
        }
      )

      if (!response.ok) {
        navigate('/')
        return
      }

      const data = await response.json()

      if (!data || Object.keys(data).length === 0) {
        navigate('/')
        return
      }

      // Map API response to bookingData fields
      setBookingData((prev) => ({
        ...prev,
        quoteID: data.quoteID,
        airportId: data.airportID,
        // :data.fullName,
        userMobileNo: data.phoneNumber,
        email: data.customerEmail,
        quoteCode: data.quoteCode,
        parkingStart: data.startDateTime,
        parkingEnd: data.endDateTime,
        // :data.createdDate,
        totalCostAll: data.totalCost,
        flyingFrom: data.flyingFrom,
        flyingTo: data.flyingTo,
        inBoundFlightNo: data.inboundFlightNo,
        outBoundFlightNo: data.outboundFlightNo,
        outBoundTerminalId: data.outboundTerminalID,
        inBoundTerminalId: data.inboundTerminalID,
        // :data.location,
        userCompanyName: data.companyName,
        // userSource :data.source,
        carCleanId: data.selectedCarCleanID,
        electricChargeId: data.selectedEChargeID,
        discountCode: data.discountCode,
        // :data.productName,
        // :data.productDescription,
        totalDaysCost: data.productPrice,
        promotions: data.isPromotions,
        // :data.isRegister,
        // :data.isFlyingfromDifferent,
        airportName: data.airportName,
        outBoundTerminalName: data.outBoundTerminalName,
        // :data.outBoundTerminalCode,
        outBoundTerminalCost: data.outBoundTerminalCost,
        inBoundTerminalName: data.inBoundTerminalName,
        // :data.inBoundTerminalCode,
        inBoundTerminalCost: data.inBoundTerminalCost,
        carCleanName: data.carCleanName,
        carCleanCost: data.carCleanPrice,
        electricChargeName: data.eChargeName,
        electricChargeCost: data.eChargePrice,
        // :data.vehicleName,
        carRegistrationNo: data.registrationNumber,
        carModelId: data.vehcileModelID,
        carColourId: data.vehicleColorID,
        carMakeId: data.vehicleMakeID,
        carOtherColourName: data.vehicleColorOther,
        carOtherMakeName: data.vehicleMakeOther,
        carOtherModelName: data.vehicleModelOther,
        // :data.vehicleVersion,
        carMakeName: data.vehicleMakeName,
        carModelName: data.vehicleModelName,
        carColourName: data.vehicleColorName,
        customerId: data.customerID,
        userFirstName: data.customerFirstName,
        userSurName: data.customerLastName,
        userTitle: data.customerTitle,
        totalDays: Math.ceil(
          (new Date(data.endDateTime) - new Date(data.startDateTime)) /
            (1000 * 60 * 60 * 24)
        ),
        formattedParkingStart: formatDisplayDate(data.startDateTime),
        formattedParkingEnd: formatDisplayDate(data.endDateTime),
      }))
    } catch (error) {
      console.error('Error fetching data:', error)
      navigate('/')
    }
  }

  return (
    <BookingContext.Provider
      value={{
        bookingData,
        setBookingData,
        cardData,
        setCardData,
        fetchDataAndValidate,
      }}
    >
      {children}
    </BookingContext.Provider>
  )
}
