import React, { useState, useEffect } from 'react'
import '../NavBar/navbar.css'
import logo from './../../assets/logo.jpg'
import { API_DASHBOARD_DOMAIN } from '../../Config/Config'

export default function Navbar() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [scrollPercentage, setScrollPercentage] = useState(0)

  const handleMouseEnter = () => {
    setIsNavbarVisible(true)
  }

  const handleMouseLeave = () => {
    if (window.scrollY > window.innerHeight) {
      setIsNavbarVisible(false)
    }
  }

  const handleScroll = () => {
    if (window.scrollY > lastScrollY && window.scrollY > window.innerHeight) {
      setIsNavbarVisible(false)
    } else {
      setIsNavbarVisible(true)
    }
    setLastScrollY(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  useEffect(() => {
    window.addEventListener('scroll', handleScrollPercentage)
    return () => {
      window.removeEventListener('scroll', () => {})
    }
  }, [])

  function handleScrollPercentage() {
    const howMuchScrolled =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    setScrollPercentage((howMuchScrolled / height) * 100)
  }

  return (
    <>
      <div
        className={`navbar-container ${isNavbarVisible ? 'visible' : 'hidden'}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <img src={logo} alt="Skaii - Logo" className="logo-size" />

            <button
              className="navbar-toggler my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-spacing">
                <li className="nav-item">
                  <a className="nav-link underline-hover" href="#">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link underline-hover" href={'#booking'}>
                    Make a Booking
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link underline-hover" href="#services">
                    Our Services
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link underline-hover" href="#about">
                    Who We Are?
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link underline-hover" href="#testimonial">
                    Testimonials
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link underline-hover" href="#faq">
                    FAQs
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link underline-hover" href="#find">
                    Find Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link underline-hover" href="#footer">
                    Contact Us
                  </a>
                </li>
              </ul>
              <form className="d-flex main-btn" role="search">
                <div className="btn btn-outline-success">
                  <a href={`${API_DASHBOARD_DOMAIN}/signup`}>Sign Up</a>
                </div>
                <div className="btn btn-outline-success">
                  <a href={`${API_DASHBOARD_DOMAIN}/login`}>Login</a>
                </div>
              </form>
            </div>
          </div>
        </nav>
      </div>
      <div className="scroll-progress-tracking-container">
        <div
          className="current-progress-bar"
          style={{
            width: `${scrollPercentage}%`,
          }}
        ></div>
      </div>
    </>
  )
}
